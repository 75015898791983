
.num{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .num{
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
}
@media (min-width:1200px){
}


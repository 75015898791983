
.sub-staff-tab {
    margin-bottom: 30px;
    ::v-deep {
        .v-tabs-bar {
            height: auto !important;
        }
        .v-tabs-bar__content {
            flex-wrap: wrap;
        }
    }
    .v-tab {
        width: 50%;
        height: 44px;
        &::after {
            display: none;
        }
    }
}
@media (min-width: 1024px) {
    .sub-staff-tab {
        margin-bottom: 50px;
        .v-tab {
            width: auto;
            height: 50px;
        }
    }
}
